import Card from "react-bootstrap/Card";
import React, {useCallback, useEffect, useState} from "react";
import {
  associatedLoanForProperty,
  updateAssociatedLoanForPropertyPosition
} from "@hornet-api/properties/associatedLoanForProperty";
import {AssociatedLoanForPropertyServer} from "@interfaces/Loan";
import {alertApiErrors} from "@common/errors";
import {AssociatedLoanRow} from "@admin-ui/pages/PropertyPage/show/AssociatedLoanRow";
import {addAlert} from "@components/Alert";
import LoadingWithWrapper from "@components/Indicators/LoadingWithWrapper";

type Props = {
  propertyId: number
}
export const AssociatedLoans = ({propertyId}: Props) => {
  const [associatedLoans, setAssociatedLoans] = useState<AssociatedLoanForPropertyServer[]>();
  const [isLoading, setIsLoading] = useState<boolean>()
  useEffect(() => {
    setIsLoading(true);
    associatedLoanForProperty(propertyId)
      .then((data) => {
        setAssociatedLoans(data?.sort((a, b) => a.position - b.position));
      })
      .catch(alertApiErrors)
      .finally(() => setIsLoading(false));
  }, [propertyId]);

  const updatePositionChange = useCallback((rowId: number, newPosition: number) => {
    updateAssociatedLoanForPropertyPosition(propertyId, rowId, newPosition).then(() => {
      if (associatedLoans) {
        setIsLoading(true);
        setAssociatedLoans(associatedLoans
          .map((al) => al.id == rowId ? {
            ...al,
            position: newPosition
          } : al)
          .sort((a, b) => a.position - b.position));
        addAlert({
          type: "success",
          content: "Position updated successfully."
        });
      }
    })
      .catch(alertApiErrors)
      .finally(() => setIsLoading(false));
  }, [propertyId, associatedLoans]);


  return <Card>
    <Card.Header>Associated Loans</Card.Header>
    <Card.Body>
      {
        isLoading
          ? <LoadingWithWrapper/>
          : <div className="table-responsive">
            <table className="table table-striped">
              <thead>
              <tr>
                <th>Position</th>
                <th>Loan ID</th>
                <th>Funding Source</th>
                <th>Stage</th>
                <th>Funding Date</th>
                <th>Maturity Date</th>
                <th>Interest Rate</th>
                <th>Principal</th>
                <th>Construction Escrow (Initial)</th>
                <th>Construction Escrow (Current)</th>
                <th>Prepaid Interest (Initial)</th>
                <th>Prepaid Interest (Current)</th>
              </tr>
              </thead>
              <tbody>
              {
                associatedLoans?.map((associatedLoan) => (
                  <AssociatedLoanRow
                    key={associatedLoan.id}
                    row={associatedLoan}
                    updatePositionChange={updatePositionChange}
                    maxLienPosition={Math.max(...associatedLoans?.map((loan) => loan.position), associatedLoans?.length, 5)}
                  />
                ))
              }
              </tbody>
            </table>
          </div>
      }
    </Card.Body>
  </Card>
}